.mobile-container {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    text-align: center;

}

.mobile-svg-container {
    position: relative;
    top: 10vh;
}

.mobile-svg {
    transform: scale(0.8);
}

.mobile-title-container {
    border-bottom: solid 1px #dcdcdc;
    height: 10vh;
    display: flex;
    flex-direction: column;

    justify-content: center;
    background-color: #3f51b5;
}

.mobile-title {
    font-size: 1.3rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: center;
    color: white;
}

.mobile-sub-title-container {
    margin-top: -60px;
    position: relative;
    top: 10vh;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mobile-sub-title {
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    text-align: center;
    color: #6a6a6a;
    margin-top: 2rem;
    margin-bottom: 0;
}

.mobile-sub-title-second {
    font-size: 1.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    text-align: center;
    color: #5b70f1;
    margin-bottom: 0;
    margin-top: 90px;
    cursor: pointer;
}