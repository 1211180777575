.main-section {
  background: linear-gradient(to right, #4ca2cd, #67b26f);
  /* height: 80vh; */
  text-align: center;
  padding: 90px 0px;
  width: 100%;
}

.main-section__title {
  color: white;
  font-size: 2rem;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.main-section-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* width: 50vw; */
}

.main-section__subtext {
  color: white;
  font-size: 1.2rem;
  line-height: 30px;
  margin: 9px;
}

.contributors-section {
  text-align: center;
  width: 100%;
  margin: 30px 0px;
  /* height: 100px; */
}

.contributors-title {
  font-weight: 500;
  color: black;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.contributors-logos {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 80%;
  flex-wrap: wrap;
  margin: auto;
}

.contributors-logos__logo {
  max-height: 80px;
  /* max-width: 50px; */
  margin: 10px 20px;
}

.datasets-section {
  background: url(../../imgs/abot_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
}

.datasets-button__button {
  transition: all 0.2s ease-in-out 0s;
  border-width: 2px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
  word-spacing: 1px;
  border-color: #252525;
  color: #252525;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
}

.datasets-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navbar-container {
  position: sticky;
  width: 100%;
  background-color: #2c2c2c;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-inner-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  width: 60%;
}

.nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-logo-title {
  color: white;
  font-size: 1.1rem;
  text-align: left;
}

.nav-datasets-title {
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
  margin-right: 15px;
}

.nav-datasets-title:hover {
  color: #67b26f;
}

.nav-datasets-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-text_bold {
  font-weight: bold;
}

@media (max-width: 600px) {
  .nav-logo-title {
    color: white;
    font-size: 0.8rem;
    text-align: left;
    white-space: nowrap;
    margin-left: -26px;
    margin-right: 20px;
  }
}
