.filetypes-card {
    font-size: 12px;
}

.filetypes-header {
    text-align: left;
    width: 100%;
    /*border-bottom: 1px solid gray;*/
    font-size: 14px;
    /*text-align: center;*/
    margin-bottom: 5px;
    padding-left: 5px;
}

.filetypes-content{
    margin: 5px;
}

.filetypes-here{
    color: #2196f3;
    cursor: pointer;
}