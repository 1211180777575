.modal-success-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modal-success-maintext{
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modal-success-successText{
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: 0.77px;
    color: #74c14f;
    font-size: 1.5rem;
    font-weight: 500;
}

.text-decoration-none{
    text-decoration: none;
}

.modal-success-catalog{
    margin-top: 70px;
}

.modal-success-subtext{
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #c2c2c2;
}

.modal-success-upload{
    font-weight: 400;
    margin-top: 15px;
    cursor: pointer;
}

.modal-success-manual-container{
    width: 100%;
    height: 50px;
    background-color: #f6f6f6;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-success-manual-text{
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #151515;
    font-size: 0.8rem;
    padding-bottom: 2px;
}

.modal-modal-success-icon{
    color:   #c2c2c2;
    height: 10px;
}