.modal-shareEmail-container{
    /*display: flex;*/
    padding: 5px;
    /*justify-content: center;*/
}

.shareEmail-header{

}

.shareEmail-addEmails-container{
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 5px 10px;
}

.shareEmail-header-text{
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.19px;
    color: #151515;
    margin-bottom: 30px;
}

.shareEmail-accessList-rowContainer{
    display: flex;
    justify-content: space-between;
    border-top: solid 1px #979797;
    background-color: #ffffff;
    padding: 20px 10px;
    /*padding-top: 20px;*/
}

.shareEmail-doneBtn-container{
    text-align: right;
    margin: 70px 0px 30px 0px;
}

.shareEmail-updateCancel-container{
    text-align: right;
    margin: 40px 0px 30px 0px;
}

.shareEmail-accessList-textIconWrapper{
    display: flex;
    align-items: center;
}

.shareEmail-accessList-mailtext{
    letter-spacing: 0.17px;
    color: #151515;
    font-size: 1.2rem;
    margin-left: 30px;
}

.shareEmail-accessList-selectWrapper{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shareemail-accesslist-select-react-select{
     width: 80%;
    margin-right: 20px;
 }

.shareEmail-accessList-delete-x{
    color: #151515;
    font-size: 1.1rem;
    font-weight: 500;
    cursor:pointer;
}

.shareEmail-dataset-button-container{
    display: flex;
    margin-left: auto;
    width: 200px;
    margin-right: 30px;
}

.add-emails-CancelBtn{
    width: 100px;
    height: 43px;
    border-radius: 2px;
    /*background-color: #3699fc;*/
    border-style: none;
    color: #3699fc;
    font-weight: normal;
    letter-spacing: 0.5px;
    font-size: 1.1rem;
    cursor:pointer;
    margin-right: 20px;
}

.shareEmail-dataset-success-msg{
    padding: 0px;
    text-align: right;
    padding-right: 20px;
    font-size: 1rem;
    font-weight: 500;
    color: #3699fc;
    margin-bottom: -10px;
}

.shareEmail-dataset-failed-msg{
    padding: 0px;
    text-align: right;
    padding-right: 20px;
    font-size: 1rem;
    font-weight: 500;
    color: #f35869;
    margin-bottom: -10px;
}