.add-emails-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-select-email-container{
    width: 25%;
    margin-left: 20px;

}

.add-emails-addBtn-container{
    text-align: right;
    margin: 20px 0px;
}

.add-emails-addBtn{
    width: 100px;
    height: 43px;
    border-radius: 2px;
    background-color: #3699fc;
    border-style: none;
    color: #ffffff;
    font-weight: normal;
    letter-spacing: 0.5px;
    font-size: 1.1rem;
    cursor:pointer;
}

.react-multi-email{
    max-width: 70%;
}