uploadContainer {
  flex-grow: 3;
}
.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.upload-title{
  margin: 30px;
  font-weight: normal;
  color: #151515;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 50vw;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.orderLabel {
  text-align: left;
  font-family: Gilroy-proj, sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #102a43;
  margin-bottom: 5px;
}

.orderInput {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  padding: 4px;
  font-family: Gilroy-proj, sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #676767;
}

.uploadFileContainer {
  height: 90px;
  border-radius: 7.5px;
  border: dashed 1.5px #e1e1e1;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
}

.uploadSvg {
  margin: 0 auto;
}

.uploadFileContainerUploading {
  height: 55px;
  border-radius: 7.5px;
  border: dashed 1.5px #e1e1e1;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
}

.uploadFileIndicatorLeft {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Gilroy-proj, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #43434a;
}

.uploadFileIndicatorRight {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 10rem;
}

.loadingUpload {
  margin: 0 auto;
  transform: rotate(171.603deg);
  position: relative;
  top: 10px;
}

.uploadTxt {
  margin: 0;
  font-family: Gilroy-proj, sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #43434a;
}

.freeTxt {
  margin: 0;
  font-family: Gilroy-proj, sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #43434a;
}

.submitContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.submitBtn {
  border-radius: 2px;
  background-color: #2196f3;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  padding: 9px;
  width: 70px;
  cursor: pointer;
  border: none;
}

.submitBtn:hover{
  background-color: #2169B3;
}
.resetBtn {
  border-radius: 2px;
  background-color: #f35f5e;
  color: white;
  font-family: Gilroy-proj, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  padding: 9px;
  /*width: 70px;*/
  cursor: pointer;
  border: none;
  margin-right: 15px;
}

.resetBtn:hover{
  background-color: #b15959;
}

.resetForm {
  margin-right: 1rem;
  font-family: Gilroy-proj, sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.form-control {
  color: transparent;
  width: 36px;
  height: 14px;
}

.form-control::-webkit-file-upload-button {
  display: none;
}

.form-control::before {
  content: "browse";
  color: #3599fc;
  font-family: Gilroy-proj, sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  width: 36px;
  height: 14px;
}

.disable {
  background-color: #bdbdbd;
  cursor: auto;
  pointer-events: none;
}

.disableFormReset {
  cursor: auto;
  pointer-events: none;
}

.percentageView {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uploadProgressTitle {
  opacity: 0.5;
  font-family: Gilroy-proj, sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #121212;
}

.uploadProgressNumber {
  text-align: left;
  font-size: 20px;
}

.successText {
  font-size: 2rem;
  color: #3599fc;
}

.noaccess-container {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.noaccess-container h1 {
  font-size: 1.7rem;
}

.upload-select-container{
  display: flex;
  margin: 10px 0px;
  align-items: center;
}

.upload-select{
  padding: 10px 30px;
  margin-right: 10px;
  color: #151515
}

.onUploadContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.onUploadContainer-progress{
  margin-left: 50px;
}

.onUpload-uploadingText{
  font-weight: normal;
  font-size: 0.8rem;
}

.onUploadContainer-progress-textContainer{
  text-align: left;
  margin-left: 20px;
}

.onUpload-progress-percentageText{
  font-weight: bold;
  font-size: 1.3rem;
}
.onUpload-progress-secondaryText{
   font-size: 0.7rem;
   color: #83848d;
}

.onUploadContainer-progress-circular{
  transform: scale(1.2);
}

.upload-error{
  color: #f35869;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px;
}

.returnCatalog-container{
  width: 180px;
  height: 31px;
  /*margin:50px 0px 0px 60px;*/
  border-radius: 2px;
  border: solid 1px #3699fc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: #151515;

}

.submitBtn:disabled{
  cursor: not-allowed;
}

.upload_link{
  text-decoration: none;
  position: absolute;
  left: 75px;
}

.returnCatalog-svg{
  margin-right: 5px;
  height: 14px;
}

.upload-header-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}


.upload-country-container{
  width: 50%;
  margin-top: 10px;
}

.upload-country-select{
  width: 100%;
  margin:15px 0px;
  padding: 10px;
}

.upload-share-text-container{
  text-align: left;
  color: #151515;
  font-weight: 500;
  font-size: 0.9rem;
  margin: 10px 5px;
}

.upload-extra-fields-container{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;

}
.upload-extra-fields-box{
  width: 50%;
  text-align: left;
}

.upload-extra-fields-box > .MuiFormControl-root {
  width: 95%;
  margin: 5px;
}

.reset-submit-btn-container{
  margin-bottom: 50px;
}

.dateinput{
  background-color: transparent;
  width: 96%;
  padding: 18px 0px;
  padding-left: 10px;
  text-align: left;
  /* border-radius: 4px; */
  border: none;
  /* border: 1px solid hsl(0,0%,80%); */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}

.react-datepicker-wrapper{
  width: 95%;
  margin: 5px;
}

.react-datepicker__input-container{
  border: 1px solid hsl(0,0%,80%);
  border-radius: 4px;
  width: 100%;
}

.upload-sharewithemails-container{
  display: flex;
  margin-top:15px;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -10px;
}

.upload-sharewithemails-maintext{
  color: #636363;
  padding-bottom: 3px;
  margin-left: 10px;
  white-space: nowrap;
}

.upload-sharewithemails-textContainer{
  display: flex;
}

.upload-sharewithemails-edit{
  color: #3699fc;
  cursor: pointer;
  margin-left: 50px;
}

.upload-share-privately-text{
  text-align: left;
  color: #151515;
  font-weight: 500;
  font-size: 0.9rem;
}
.upload-switches-container{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 10px
}

.upload-sharewithgroups-container{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: -10px;
}

.upload-select-groups-container{
  min-width: 180px;
  margin-left: 110px;
}

.upload-recaptcha-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.upload-text-filesize{
  margin-top: 2px;
  color: #83848d;
  font-size: 0.7rem;
}

.upload-text-trouble{
  text-align: left;
  font-size: 0.7rem;
  margin-bottom: 15px;
  font-weight: 500;
  color: #151515;
  padding-left: 5px;
}

@media (max-width: 920px){
  .upload-select-groups-container{
    min-width: 140px;
    margin-left: 80px;
  }
}

@media (max-width: 775px){
  .upload-header-container{
    flex-direction: column;
  }
  .upload_link{
    margin: 10px;
    position: static;
    left: 0px;
  }
  .upload-select-groups-container{
    min-width: 100px;
    margin-left: 50px;
  }

}

